<template>
    <div class="wrapper">
        <div class="headline">
            <div class="name hiddenHeader">Hierarchie</div>
            <div class="chars hiddenHeader">階層</div>
            <img class="image hiddenHeader" :src="require('@/assets/images/hierarchy.png')">
        </div>
        <div class="body">
            <div class="description" v-for="(item, index) in hierarchyData" :key="index">
                <img v-if="item.image" class="image hiddenHeader" :src="require('@/assets/images/' + item.image)">
                <div class="name hidden" v-text="item.name"></div>
                <div class="char hidden">
                    <span class="second-font" v-text="item.desc"></span>
                    <span v-text="item.chars"></span>
                </div>
                <div class="hidden" v-html="item.description"></div>
                <img v-if="item.imageSmall" class="image-small hiddenHeader" :src="require('@/assets/images/' + item.imageSmall)">
                <hr v-if="item.last" class="horizontal-line">
            </div>
        </div>
    </div>
</template>

<script>
import BaseView from './BaseView.vue';

export default {
    name: 'HierarchyView',
    extends: BaseView,
    data() {
        return {
            hiddenHeaderElements: [],
            hiddenElements: [],
            hierarchyData: [
                {
                    name: "Shōgunat",
                    desc: "Leaderschaft",
                    chars: "公儀",
                    description: "<p>Das Shogunat ist der Verwaltungsapparat des Shogun. Im Japanischen bezeichnete es sich selbst als kogi („offizielle Angelegenheiten“). Es bildet den wichtigen Kern des Miyamoto Clans und wird von den ihnen unterliegenden Samurai verehrt. Im Shogunat werden alle bedeutenden Entscheidungen gemeinsam getroffen, wobei dem Shogun als oberster Autorität stets das letzte Wort zukommt.</p><p>Die Bindung an das Shogunat ist für Samurai von höchster Bedeutung, da sie ihre Loyalität und Treue zu ihrem Herrn, dem Shogun, bekräftigt. Diejenigen, die dem Shogunat untreu werden oder es ablehnen, riskieren ihren eigenen Tod.</p><p>Um Teil dieses mächtigen Gefüges zu werden, muss man sich den tiefen Respekt des Shogun verdienen und über außergewöhnliche Expertise verfügen. Der Weg in die Reihen des Shogunats ist von harten Prüfungen und unablässigem Streben nach Exzellenz gezeichnet. Nur diejenigen, die sich als würdig erweisen und die Last der Verantwortung tragen können, werden Einlass finden und die Ehre haben, ihr Können und ihre Hingabe im Dienst des Shogun zu entfalten.</p><p> Das Shogunat ist ein unverrückbares Band, das nur durch das finale Geleit in die Ewigkeit durchbrochen werden kann. Das heißt, der Austritt ist nur durch den Tod möglich.</p>",
                    last: true
                },
                {
                    name: "Shōgun",
                    desc: "Rang 12",
                    chars: "侍",
                    description: "<p>Shogun - vollständiger Titel Seii Taishogun („Barbaren unterwerfender großer General“/„Generalissimus“) - ist seit dem 12. Jahrhundert ein japanischer Militärtitel für Anführer aus dem Kriegeradel der Samurai. Innerhalb des Miyamoto Clans gilt der Shogun als der ehrenwerteste Samurai und bildet die höchste Instanz des Clans.</p><p>Seine Macht und Autorität erstrecken sich über alle Samurai des Clans und seine Befehle werden ohne Zögern und Bedenken ausgeführt. Unter dem Banner des Shogun vereinen sich die Samurai des Clans zu einer unerschütterlichen Einheit, bereit, ihm mit absoluter Hingabe zu folgen. So wird der Shogun nicht nur als Leiter des Miyamoto Clans verehrt, sondern er gilt auch als ein Symbol für die Einheit und Stärke des Clans.</p>"
                },
                {
                    name: "Shikken",
                    desc: "Rang 11",
                    chars: "執権",
                    description: "<p>Der Shikken ist eine herausragende Figur im Miyamoto Clan, als die rechte Hand des Shoguns. Diese Position ist von höchster Bedeutung, denn der Shikken agiert als direkter Vertrauter des Shoguns und wird persönlich von ihm ausgewählt. Diese Wahl unterstreicht das tiefe Vertrauen, das der Shogun in den Shikken setzt. Als Vertrauensperson des Shoguns übernimmt der Shikken bedeutende Aufgaben und trägt maßgeblich zur Führung und Organisation des Clans bei. Seine Autorität und sein Einfluss erstrecken sich über verschiedenste Angelegenheiten und sein Wort hat oft das Gewicht des Shoguns selbst.</p>"
                },
                {
                    name: "Rensho",
                    desc: "Rang 10",
                    chars: "連署",
                    description: "<p>Der Rensho („Ko-Signator“) spielt eine entscheidende Rolle als Assistent des Shikken im Miyamoto Clan. Seine Position wird durch den Shikken selbst ausgewählt, wobei der Shogun beratend zur Seite steht. Dieser Auswahlprozess verdeutlichen die Bedeutung der Vertrauensbeziehung zwischen dem Shikken und dem Rensho sowie ihre gemeinsame Verantwortung gegenüber dem Shogun und dem Clan. Als rechte Hand des Shikkens unterstützt der Rensho bei der Ausführung wichtiger Aufgaben und Entscheidungen.<p></p>Seine Rolle umfasst vielfältige Verantwortlichkeiten, von administrativen Aufgaben bis hin zur Vertretung des Shikkens in seiner Abwesenheit. Der Rensho trägt dazu bei, die Effizienz und Wirksamkeit der Führung des Clans zu gewährleisten und steht dem Shikken loyal zur Seite, während er gleichzeitig die Vision und Anliegen des Shoguns im Auge behält.</p>"
                },
                {
                    name: "Tairō",
                    desc: "Rang 9",
                    chars: "大老",
                    description: "<p>Der Tairo spielt eine wichtige beratende Rolle innerhalb des Shogunats und trägt zur Stärkung der Beziehung zwischen dem Shogunat und den Samurai bei. Als Mitglied der untersten Riege des Shogunats ist seine Funktion von entscheidender Bedeutung für die Verwaltung und Stabilität des Miyamoto Clans. Der Tairo ist bekannt für seine Weisheit und sein diplomatisches Geschick, was es ihm ermöglicht, als Vermittler zwischen den verschiedenen Interessengruppen zu agieren. Seine Aufgabe ist es, die Anliegen der Samurai zu vertreten und gleichzeitig die Autorität des Shogunats zu wahren. Durch seine beratende Funktion trägt der Tairo dazu bei, Konflikte zu lösen, Kompromisse zu finden und eine harmonische Zusammenarbeit zwischen dem Shogunats und den Samurai zu fördern.</p>",
                    last: true
                },
                {
                    name: "Samurai",
                    desc: "Memberschaft",
                    chars: "侍",
                    description: "<p>Die Samurai bilden die Elite des Miyamoto Clans und sind unverzichtbare Mitglieder sowohl für den Clan als auch für das Shogunat. Der Begriff „Samurai“ wird sinngemäß mit „dienen“ übersetzt. Diese Krieger verkörpern die Essenz der Ehre, des Mutes und der Loyalität. Sie sind nicht nur die treuen Verteidiger des Shogunats, sondern auch die eifrigsten Vertreter seiner Interessen, insbesondere der des Shoguns selbst.</p><p>Die Samurai des Miyamoto Clans folgen einem strengen Kodex, bekannt als Bushido, was sinngemäß den „Weg des Kriegers“ bedeutet. Dieser Kodex legt die Grundprinzipien fest, nach denen die Samurai leben und handeln, darunter Ehre, Tapferkeit, Gerechtigkeit und Selbstbeherrschung. Für einen Samurai ist das Streben nach Perfektion in allen Bereichen seines Lebens eine Lebensweise.</p><p>Die Samurai sind nicht nur hervorragende Krieger, sondern auch Meister der Kultur und der Künste. Sie widmen sich der Ausbildung ihres Geistes und ihrer Körper, um nicht nur im Kampf, sondern auch im täglichen Leben zu exzellieren. Ihre Disziplin und Hingabe machen sie zu Vorbildern für die Gesellschaft und zu unverzichtbaren Säulen der Stabilität des Miyamoto Clans.</p>",
                    last: true,
                    image: "hierarchy2.png"
                },
                {
                    name: "Karō",
                    desc: "Rang 8",
                    chars: "家老",
                    description: "<p>Der Karo ist ein Ehrenrang, der den höchsten Status unter den Samurai innehat. Sein Titel wird wörtlich aus dem Japanischen als „Hausältester“ übersetzt. Nur der Shogun selbst hat die Befugnis, diesen außergewöhnlichen Rang zu verleihen. Die Verleihung des Karo-Rangs erfolgt in Anerkennung langer Loyalität oder außergewöhnlicher Leistungen zugunsten des Miyamoto Clans.</p>",
                },
                {
                    name: "Kyōshi",
                    desc: "Rang 7",
                    chars: "教師",
                    description: "<p>Der Kyoshi ist ein ehrenvoller Rang unter den Samurai, der eine Fülle an Erfahrung hat und die höchste Stufe der Selbstbeherrschung verkörpert. Sein Titel wird mit „Lehrer“ übersetzt, da er die Verantwortung hat, die Werte und Prinzipien, für die die Samurai stehen, an die weniger erfahrenen Mitglieder des Clans weiterzugeben.</p>",
                },
                {
                    name: "Shōrai",
                    desc: "Rang 6",
                    chars: "小礼",
                    description: "<p>Shorai ist ein Rang aus dem altjapanischen Rangsystem „Kan'i juni kai“, dessen Name „helles Rot“ bedeutet. Um diesen ehrenvollen Rang zu erreichen, muss ein Samurai eine herausragende Leistung erbringen, die eine bedeutende positive Auswirkung auf das Ansehen und den Ruf des Miyamoto Clans hat.</p>",
                },
                {
                    name: "Dairai",
                    desc: "Rang 5",
                    chars: "大礼",
                    description: "<p>Dairai ist ebenso aus dem japanischen Rangsystem, wobei der Name „dunkles Rot“ bedeutet. Um diesen Rang zu erreichen, muss ein Samurai dazu beitragen, den Miyamoto Clan innerhalb seiner eigenen Reihen zu stärken und zu festigen.</p>",
                },
                {
                    name: "Shōnin",
                    desc: "Rang 4",
                    chars: "小仁",
                    description: "<p>Die Rangbezeichnung Shonin steht für „helles Blau“. Dieser Rang wird an Mitglieder verliehen, die seit langem dem Clan angehören und den Weg des Schwertes in besonderem Maße repräsentieren.</p>",
                },
                {
                    name: "Dainin",
                    desc: "Rang 3",
                    chars: "大仁",
                    description: "<p>Der Dainin-Rang, dessen Name „dunkles Blau“ bedeutet, markiert den Höhepunkt der Ausbildung und Entwicklung eines Samurai im Miyamoto Clan. Auf diesem Rang haben die Meister der Person alle Lehren der Samurai und den Weg des Schwertes weitergegeben, und sie wird nun durch ein feierliches Ritual ein vollständiger Samurai. Nun beginnt der Weg, den Miyamoto Clan mit seiner Stärke und seinem Engagement erblühen zu lassen.</p>",
                },
                {
                    name: "Shōtoku",
                    desc: "Rang 2",
                    chars: "小徳",
                    description: "<p>Shotoku bedeutet „helles Violett“ und kennzeichnet einen bedeutenden Fortschritt auf dem Weg des Schwertes im Miyamoto Clan. Personen, die diesen Rang erreichen, haben bereits einiges über die Ehre und die Selbstbeherrschung der Samurai gelernt und sind auf dem besten Weg, exzellente Samurai zu werden.</p>",
                },
                {
                    name: "Daitoku",
                    desc: "Rang 1",
                    chars: "大徳",
                    description: "<p>Der Daitoku-Rang, dessen Name „dunkles Violett“ bedeutet, markiert einen bedeutsamen Meilenstein auf dem Weg des Schwertes und zum Beginn der Samurai Ausbildung im Miyamoto Clan. Um diesen Rang zu erreichen, hat sich die Person bereits bewiesen und gezeigt, dass sie würdig ist, ein vollwertiger Samurai zu werden. Nun liegt es an ihr, sich mit voller Hingabe den Lehren der Samurai zu widmen und sich weiter zu beweisen.</p>",
                },
                {
                    name: "Minarai",
                    desc: "Rang 0",
                    chars: "見習い",
                    description: "<p>Der Minarai ist ein Lehrling im Miyamoto Clan, der noch dabei ist, sich zu beweisen und zu zeigen, dass er würdig ist, in die Lehren der Samurai und den Weg des Schwertes eingewiesen zu werden. Dieser Rang markiert den Anfang einer Reise, in der der Minarai sich den traditionellen Werten und Praktiken der Samurai widmet und sich darauf vorbereitet, ein vollwertiges Mitglied des Clans zu werden.</p>",
                    last: true
                },
                {
                    name: "Tatsujin",
                    desc: "Ausbildung",
                    chars: "達人",
                    description: "<p>Der Tatsujin ist der Meister im Miyamoto Clan und übernimmt die bedeutende Aufgabe, die Lehren der Samurai an die kommenden Generationen weiterzugeben. Er begleitet die Minarai, die Lehrlinge des Clans, bei ihrem Eintritt und unterstützt sie auf ihrem Weg, ein vollwertiger Samurai zu werden. Darüber hinaus bildet der Tatsujin die Daitoku und Shotoku aus, um sicherzustellen, dass sie den Pfad des Samurai mit voller Hingabe und Meisterschaft gehen.</p><p>Als Hüter der Traditionen und Prinzipien des Bushido spielt der Tatsujin eine entscheidende Rolle dabei, sicherzustellen, dass jeder Samurai im Clan die moralische Integrität und die notwendigen Fähigkeiten besitzt, um seine Rolle angemessen auszufüllen. Er prüft sorgfältig, ob jeder Samurai die notwendigen Voraussetzungen erfüllt und bereit ist, die Werte des Clans zu repräsentieren.</p><p>Durch seine Weisheit und sein Engagement trägt der Tatsujin dazu bei, die Stärke und den Ruf des Miyamoto Clans zu festigen und zu erweitern. Seine Führung und sein Beispiel dienen als Inspiration für alle Mitglieder des Clans und sichern die Zukunft der Samurai-Tradition im Miyamoto Clan.</p>",
                    image: "hierarchy3.png"
                },
                {
                    name: "Kanrisha",
                    desc: "Logistik",
                    chars: "管理者",
                    description: "<p>Der Kanrisha im Miyamoto Clan trägt die Verantwortung für die Logistik und die Aufrechterhaltung der grundlegenden Struktur innerhalb des Clans. Er überwacht sorgfältig die Versorgung und stellt sicher, dass alle notwendigen Güter und Ressourcen im Besitz des Miyamoto Clans sind.</p><p>Als Hüter der logistischen Abläufe spielt der Kanrisha eine entscheidende Rolle dabei, sicherzustellen, dass der Clan reibungslos funktioniert und alle Bedürfnisse seiner Mitglieder erfüllt werden. Er organisiert Transporte, verwaltet Lagerbestände und koordiniert die Verteilung von Ressourcen, um sicherzustellen, dass der Clan in jeder Hinsicht gut versorgt ist.</p>",
                },
                {
                    name: "Shinigami",
                    desc: "Leibgarde",
                    chars: "死神",
                    description: "<p>Der Shinigami, wörtlich übersetzt als „Gott des Todes“, ist in der japanischen Kultur eine symbolische Darstellung des Todes und derjenigen Gottheiten, die die Seelen der Verstorbenen in die Welt der Toten führen. Im Miyamoto Clan erfüllt der Shinigami jedoch eine andere Rolle: Er dient als Leibwache des Shogunats.</p><p>Die Aufgabe des Shinigami besteht darin, das Shogunat mit seinem eigenen Leben zu beschützen und strikt allen Befehlen zu gehorchen, die ihm gegeben werden. Als eine Eliteeinheit innerhalb des Miyamoto Clans ist die Identität der Shinigami streng geheim gehalten, um ihre Effektivität und ihre Fähigkeit, unerkannt zu agieren, zu gewährleisten.</p><p>Die Shinigami sind hochqualifizierte Kämpfer, die sowohl im Nahkampf als auch im strategischen Denken herausragend sind. Sie stehen als unerschütterliche Wächter an vorderster Front, um die Sicherheit und Stabilität des Shogunats zu gewährleisten.</p><p>Um ein Shinigami zu werden, muss ein Samurai im Miyamoto Clan den Status eines vollwertigen Samurai erreichen, bekannt als Dainin. Die Mitglieder der Eliteeinheit der Shinigami werden persönlich vom Shogun ernannt.</p>",
                    imageSmall: "hierarchy4.png",
                },
                {
                    name: "Yuta",
                    desc: "Mediziner",
                    chars: "雄太",
                    description: "<p>Früher waren die Yuta japanische Schamanen, dessen Wirkungsbereiche vor allem die Heilung von Krankheiten, die Kommunikation mit Geistern und die Wahrsagerei waren. Heute sind sie im Miyamoto Clan hochgeschätzten Mediziner, deren Aufgabe es ist, den Clan in allen Belangen der Gesundheit zu unterstützen. Die fähigen Yuta sind nicht nur Meister in der Kunst der traditionellen Medizin, sondern auch in den neuesten Techniken der Medizin und Heilkunst bewandert. Sie stehen stets bereit, um Verletzungen zu behandeln, Krankheiten zu heilen und das Wohlbefinden der Mitglieder des Clans zu gewährleisten, sei es auf dem Schlachtfeld oder im täglichen Leben. Mit ihrem tiefen Wissen und ihrer unermüdlichen Hingabe verkörpern die Yuta die Essenz der Fürsorge und Unterstützung innerhalb des Miyamoto Clans.</p>",
                    last: true
                },
            ]
        }
    },
    mounted() {
        window.scrollTo(0, 0);
        this.hiddenHeaderElements = document.querySelectorAll('.hiddenHeader');
        this.hiddenElements = document.querySelectorAll('.hidden');
        this.scrollBehaviour(true, this.hiddenHeaderElements, []);
        this.scrollBehaviour(false, [], this.hiddenElements);
    }
}
</script>

<style lang="scss" scoped>
@import "../style/shared.scss";
@import "../style/hierarchy-view.scss";
</style>
