<template>
    <div class="wrapper">
        <div class="headline">
            <div class="name hiddenHeader">Historie</div>
            <div class="chars hiddenHeader">話</div>
            <img class="image hiddenHeader" :src="require('@/assets/images/history.png')">
        </div>
        <div class="body">
            <div class="description" v-for="(item, index) in historyData" :key="index">
                <img v-if="item.image" class="image hiddenHeader" :src="require('@/assets/images/' + item.image)">
                <div class="name hidden" v-text="item.name"></div>
                <div class="char hidden">
                    <span v-if="item.desc" class="second-font" v-text="item.desc"></span>
                    <span v-text="item.chars"></span>
                </div>
                <div class="hidden" v-html="item.description"></div>
                <!--eslint-disable-next-line--> 
                <div v-if="item.rules"  class="rules hidden" v-for="(rule, index2) in item.rules" :key="index2">
                    <div class="number" v-text="index2 + 1 + '. '"></div>
                    <div v-text="rule"></div>
                </div>
                <hr class="horizontal-line">
            </div>
        </div>
    </div>
</template>

<script>
import BaseView from './BaseView.vue';

export default {
    name: 'HistoryView',
    extends: BaseView,
    data() {
        return {
            hiddenHeaderElements: [],
            hiddenElements: [],
            historyData: [
                {
                    name: "Auftakt",
                    chars: "開始",
                    description:
                        "<p>Der Miyamoto Clan war eine alte und angesehene Gemeinschaft von Samurai-Kriegern, die seit Generationen in Japan lebten. Der Gründer des Clans, Musashi Miyamoto, war ein legendärer Krieger und Schwertkämpfer, der für seine unübertroffene Fähigkeit im Kampf bekannt war. Musashi Miyamoto wurde im 16. Jahrhundert geboren und wuchs in einer Zeit des Krieges und der Unruhe auf. Er wurde frühzeitig in den Weg des Schwertes eingeführt und trainierte hart, um seine Fähigkeiten zu perfektionieren. Schon bald wurde er zu einem der gefürchtetsten Krieger des Landes und gewann zahlreiche Schlachten.</p><p>Musashi Miyamoto gründete den Miyamoto Clan, um seine Kampfkunst und seine Philosophie des Schwertes an die nächsten Generationen weiterzugeben. Die Miyamoto-Krieger waren bekannt für ihre Disziplin, ihre Tapferkeit und ihre unerschütterliche Treue zu ihrem Clan und ihrem Land. Sie kämpften in vielen Schlachten und verteidigten ihr Land und ihre Ehre mit großem Mut und Hingabe.</p><p>Musashi Miyamoto selbst wurde zu einer Legende in Japan und seine Lehren über den Weg des Schwertes wurden von vielen Schülern und Nachfolgern weitergetragen. Sein Vermächtnis lebt bis heute in den Herzen und Seelen der Menschen weiter, die den Geist der Samurai und die Weisheit des Miyamoto Clans ehren.</p>",
                },
                {
                    name: "Weg nach Los Santos",
                    chars: "パス",
                    description:
                        "<p>Zeiten änderten sich und somit auch der Miyamoto Clan. Im Laufe der Jahre geriet der Clan in finanzielle Schwierigkeiten und sah sich gezwungen, andere Wege zu finden, um ihren Lebensunterhalt zu verdienen.</p><p>Nachdem der Miyamoto Clan beschlossen hatte, sich in kriminelle Aktivitäten zu verwickeln, verloren sie schnell ihren Ruf und wurden von der Gesellschaft verstoßen. Auf der Flucht vor den Behörden und auf der Suche nach einem neuen Leben zog der Clan schließlich nach San Andreas, einer Insel, die für ihre kriminelle Unterwelt bekannt ist.</p><p>In der Stadt Los Santos fand der Miyamoto Clan schnell Anschluss an örtliche Gangs und Mafien und begann, sich in verschiedenen illegalen Geschäften zu engagieren, um ihren Lebensunterhalt zu verdienen. Dies haben sie jedoch nur im Schatten der Hochhäusern gemacht und sind bisher unter dem Radar geblieben. Obwohl sie sich in einer Welt voller Gewalt und Korruption wiederfanden, waren die Mitglieder des Miyamoto Clan entschlossen, zusammenzuhalten und sich gegenseitig zu schützen.</p>",
                },
                {
                    name: "Neuanfang",
                    chars: "きょう",
                    description:
                        "<p>Der Tod des aktuellen Shogun des Miyamoto Clans in Los Santos erschütterte die gesamte Stadt. Ryomato, ein gefürchteter und respektierter Shogun, war bekannt für seine scharfen Verstand und seine eiserne Hand, mit der er sein Territorium kontrollierte. Sein plötzlicher Tod hinterließ eine große Lücke in der Hierarchie des Clans und löste eine Welle von Spekulationen und Machtkämpfen aus.</p><p>Der Miyamoto Clan war kurz davor, komplett auseinanderzubrechen. Brian Brinkman-McFly, ein aufstrebendes Mitglied des Clans, sah jedoch eine Chance, den Clan wieder zusammenzuführen. Er sammelte seine engsten Vertrauten um sich, damit der Miyamoto Clan wieder in seinem alten Glanz erblüht.</p><p>Als neuer Shogun des Clans war er entschlossen, die Macht und den Einfluss seines Vorgängers zu übernehmen und das Territorium des Clans weiter auszubauen. Er war bereit, jedes Risiko einzugehen und jeden Gegner zu überwinden, um seine Position zu festigen und den Miyamoto Clan zu neuer Größe zu führen. Die Geschichte des Miyamoto Clans in Los Santos war noch lange nicht zu Ende. Unter der Führung von Brian Brinkman-Mcfly geht sie gerade erst richtig los.</p>",
                },
                {
                    image: 'history2.png',
                    name: "Bushidō",
                    chars: "武士道",
                    description:
                        "<p>Bushido, der „Weg des Kriegers“, ist der Verhaltenskodex und die philosophische Grundlage der Samurai, deren Ursprünge bis ins späte japanische Mittelalter zurückreichen. Dieser Kodex ist geprägt von den Lehren des Shinto, Buddhismus und Konfuzianismus und betont Tugenden wie Ehre, Tapferkeit, Loyalität, Selbstbeherrschung und Respekt. Der Bushido besteht aus acht grundlegenden Prinzipien, eine Zahl, die in Japan als Glückszahl gilt.</p><p>Im Miyamoto Clan wird Bushido als unerschütterliche Richtlinie verehrt, die jeden Samurai dazu verpflichtet, diese Tugenden zu leben und zu verteidigen. Die Mitglieder des Clans sind dazu angehalten, ihre Pflichten gegenüber ihrem Herrn, ihrem Clan und ihrem Land über alles zu stellen.</p><p>Die Einhaltung des Bushido ist von entscheidender Bedeutung für die Integrität und den Ruf des Miyamoto Clans. Sollte ein Samurai diese Grundsätze missachten oder gegen sie verstoßen, wird dies als schwerwiegender Verrat betrachtet und mit dem Tode bestraft.</p>",
                    rules: [
                        "Der Shogun ist das höchste Gut und wird mit unserem Leben beschützt.",
                        "Das Shogunat verdient absolute Ehre und Respekt und wer diesen verrät, wird mit dem Tode bestraft.",
                        "Jeder Samurai steht bedingungslos für seine Mitbrüder und -schwestern ein und verrät sie niemals.",
                        "Die Lehren des Weges des Schwertes werden mit vollkommener Hingabe und Erfüllung gefolgt.",
                        "Der Kirschblütenbaum ist ein heiliges Symbol und wird mit tiefster Verehrung behandelt.",
                        "Mediziner werden wie Familie geschätzt für ihre Hilfe in Zeiten der Not.",
                        "Ein Samurai unterwirft sich einzig dem Kodex des Bushido und dem Weg des Schwertes - alle anderen Gesetze werden abgelehnt.",
                        "Freunden reichen wir die Hand in Einigkeit, doch unseren Feinden begegnen wir mit dem Hass in unseren Herzen."
                    ]
                },
                {
                    image: 'history3.png',
                    name: "Weg des Schwertes",
                    chars: "剣術",
                    description:
                        "<p>Der Weg des Schwertes, auch bekannt als „Kenjutsu“, wurde vom Gründer Musashi Miyamoto entwickelt und repräsentiert die Essenz aller Lehren über den Umgang mit dem Schwert sowie die grundlegenden Tugenden eines Samurais: Ehre, Tapferkeit, Gerechtigkeit und Selbstbeherrschung. Diese uralte Disziplin ist nicht nur eine Technik des Kampfes, sondern auch ein Weg des Lebens, der den Geist und den Körper eines Samurai formt.</p><p>Die Meister des Miyamoto Clans tragen den Weg des Schwertes an die Neulinge weiter und lehren sie in verschiedenen Disziplinen. Sie vermitteln nicht nur die technischen Aspekte des Schwertkampfes, sondern auch die moralischen und ethischen Prinzipien, die mit dem Tragen eines Schwertes und dem Daseins eines Samurai einhergehen.</p><p>Der Weg des Schwertes ist eine anspruchsvolle Lehre, die das Ziel hat, dass jeder Schüler seine innere Mitte findet und im Einklang mit allem um ihn herum agiert. Es erfordert Hingabe, Entschlossenheit und einen unerschütterlichen Glauben an die Werte des Bushido, um diesen Pfad zu beschreiten.</p><p>Durch die Beherrschung des Weges des Schwertes strebt der Samurai danach, nicht nur ein Meister des Kampfes zu werden, sondern auch eine Quelle der Weisheit, der Ruhe und der Stärke für sich selbst und andere. Letztendlich geht es darum, ein Leben zu führen, das von Ehre, Respekt und dem Streben nach Perfektion geprägt ist.</p>",
                },
                {
                    image: 'history4.png',
                    name: "Hyakushō",
                    desc: "Untergruppierung",
                    chars: "百姓",
                    description:
                        "<p>In der feudalen Ära Japans spielte die Gruppe der Hyakusho eine bedeutende Rolle als Bauernadel. Sie waren verantwortlich für die Sammlung von Heilkräutern und perfektionierten die Kunst der Katana-Schmiede für den Miyamoto Clan. Im Gegenzug schützten die Samurai die Hyakusho mit ihrem Leben.</p><p>Die Philosophie des Weges des Schwertes, wie von Musashi Miyamoto gelebt und gelehrt, stand stets im Widerspruch dazu, dass die Hyakusho die Lehren der Samurai erlernen sollten. Trotzdem wurde im Laufe der Jahrhunderte aufgrund ihrer treuen Loyalität dem Miyamoto Clan gegenüber nachgegeben. Die Hyakusho konnten durch das Ablegen eines Blutschwurs vor dem Shogun selbst die Befugnis erhalten, die Grundlagen des Weges des Schwertes zu erlernen.</p><p>Diese einzigartige Beziehung zwischen den Samurai und den Hyakusho zeugt von einem komplexen Geflecht von Loyalität, Tradition und gegenseitiger Abhängigkeit. Noch heute liefern die Hyakusho alle notwendigen Ressourcen an den Miyamoto Clan, damit dieser in vollem Glanz erblühen kann. Da sie nach dem Blutschwur auch die Lehren der Samurai weitergetragen bekommen, kämpfen sie auch Seite an Seite mit dem Miyamoto Clan bis in den Tod, da sie dem Clan für ihre Jahrhundertelange Hilfe sehr dankbar sind.</p>",
                },
            ]
        }
    },
    mounted() {
        window.scrollTo(0, 0);
        this.hiddenHeaderElements = document.querySelectorAll('.hiddenHeader');
        this.hiddenElements = document.querySelectorAll('.hidden');
        this.scrollBehaviour(true, this.hiddenHeaderElements, []);
        this.scrollBehaviour(false, [], this.hiddenElements);
    }
}
</script>

<style lang="scss" scoped>
@import "../style/shared.scss";
@import "../style/history-view.scss";
</style>
