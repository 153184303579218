<template>
    <div class="wrapper">
        <div class="headline">
            <div class="name hiddenHeader">Shōgunat</div>
            <div class="chars hiddenHeader">現れる</div>
            <img class="image hiddenHeader" :src="require('@/assets/images/shogunat.png')">
        </div>
        <div class="body">
            <div class="description" v-for="(item, index) in shogunatData" :key="index">
                <div class="name hidden" v-text="item.name"></div>
                <div class="visum hidden" v-text="'Visumstufe ' + item.visum"></div>
                <div class="rank hidden" v-html="item.rang"></div>
                <div class="hidden" v-html="'Führungserfahrungen:'"></div>
                 <!--eslint-disable-next-line--> 
                <div v-for="(item, index2) in item.fraktions" :key="index2" class="hidden" v-text="item"></div>
                <hr class="horizontal-line">
            </div>
        </div>
    </div>
</template>

<script>
import BaseView from './BaseView.vue';

export default {
    name: 'ShogunatView',
    extends: BaseView,
    data() {
        return {
            hiddenHeaderElements: [],
            hiddenElements: [],
            shogunatData: [
                {
                    name: "Brian Brinkman McFly",
                    visum: 69,
                    rang: "Shōgun | Rang 12",
                    fraktions: ["Deadlock (Rang 9)", "Organisazija (Rang 11)"]
                },
                {
                    name: "Pascal Levain",
                    visum: 76,
                    rang: "Shikken | Rang 11",
                    fraktions: ["LSPD (Rang 12)", "ICA (Rang 10)", "Organisazija (Rang 10)"]
                },
                {
                    name: "Kira Levain",
                    visum: 62,
                    rang: "Rensho | Rang 10",
                    fraktions: ["LSPD (Rang 7)", "Davis Braxton Ballas (Rang 11)", "Organisazija (Rang 10)"]
                },
                {
                    name: "Aria McFly",
                    visum: 48,
                    rang: "Tairō | Rang 9",
                    fraktions: ["Organisazija (Rang 8)", "High Rollin' Hustlers (Rang 6)"]
                },
            ]
        }
    },
    mounted() {
        window.scrollTo(0, 0);
        this.hiddenHeaderElements = document.querySelectorAll('.hiddenHeader');
        this.hiddenElements = document.querySelectorAll('.hidden');
        this.scrollBehaviour(true, this.hiddenHeaderElements, []);
        this.scrollBehaviour(false, [], this.hiddenElements);
    }
}
</script>

<style lang="scss" scoped>
@import "../style/shared.scss";
@import "../style/shogunat-view.scss";
</style>
