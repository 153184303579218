<template>
    <div class="wrapper">
        <div class="headline">
            <div class="name hiddenHeader">Miyamoto Clan</div>
            <div class="chars hiddenHeader">宮本藩</div>
            <img class="image hiddenHeader" :src="require('@/assets/images/logo.png')">
        </div>
    </div>
</template>

<script>
import BaseView from './BaseView.vue';

export default {
    name: 'HomeView',
    extends: BaseView,
    data() {
        return {
            hiddenHeaderElements: [],
        }
    },
    mounted() {
        window.scrollTo(0, 0);
        this.hiddenHeaderElements = document.querySelectorAll('.hiddenHeader');
        this.scrollBehaviour(true, this.hiddenHeaderElements, [])
    }
}
</script>

<style lang="scss" scoped>
@import "../style/shared.scss";
</style>
