<template>
    <div class="wrapper">
        <div class="headline">
            <div class="name hiddenHeader">Anwesen</div>
            <div class="chars hiddenHeader">家</div>
            <img class="image hiddenHeader" :src="require('@/assets/images/estate.png')">
        </div>
        <div class="body">
            <div v-for="(item, index) in estateData" :key="index">
                <div class="name hidden" v-text="item.name"></div>
                <div class="image-group hidden">
                    <img v-for="(image, index2) in item.images" :key="index2" :src="require('@/assets/images/estate/' + image)">
                </div>
                <hr class="horizontal-line">
            </div>
        </div>
    </div>
</template>

<script>
import BaseView from './BaseView.vue';

export default {
    name: 'EstateView',
    extends: BaseView,
    data() {
        return {
            hiddenHeaderElements: [],
            hiddenElements: [],
            estateData: [
                {
                    name: "Anwesen",
                    images: ["estate1.jpg", "estate2.jpg"]
                },
                {
                    name: "Aufstellungsort/Heiliger Baum",
                    images: ["estate3.jpg", "estate4.jpg"]
                },
                {
                    name: "Planingroom",
                    images: ["estate7.jpg"]
                },
                {
                    name: "Kleiderschrank",
                    images: ["estate8.jpg"]
                },
                {
                    name: "Helikopter Ausparker",
                    images: ["estate9.jpg"]
                },
                {
                    name: "Fahrzeug Ausparker",
                    images: ["estate10.jpg"]
                },
                {
                    name: "Fraktionslager Eingang",
                    images: ["estate11.jpg"]
                },
                {
                    name: "Waffenkammer",
                    images: ["estate12.jpg"]
                },
                {
                    name: "Little Tokio (Garage)",
                    images: ["estate5.jpg", "estate6.jpg"]
                },
            ]
        }
    },
    mounted() {
        window.scrollTo(0, 0);
        this.hiddenHeaderElements = document.querySelectorAll('.hiddenHeader');
        this.hiddenElements = document.querySelectorAll('.hidden');
        this.scrollBehaviour(true, this.hiddenHeaderElements, []);
        this.scrollBehaviour(false, [], this.hiddenElements);
    }
}
</script>

<style lang="scss" scoped>
@import "../style/shared.scss";
@import "../style/estate-view.scss";
</style>
