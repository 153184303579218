<template>
    <div class="wrapper-nav">
        <router-link class="first-item" to="/">Miyamoto Clan</router-link>
        <div class="item" v-for="(item, index) in navItems" :key="index">
            <router-link :to="item.route" v-text="item.name"></router-link>
            <span v-if="index != navItems.length - 1" class="divider">-</span>
        </div>
        <audio class="audio" ref="audio" loop autoplay controls>
            <source src="../assets/fluet.mp3" type="audio/mpeg">
        </audio>
    </div>
</template>

<script>
export default {
    name: "NavigationBar",
    data() {
        return {
            navItems: [
                {
                    route: '/history',
                    name: 'Historie'
                },
                {
                    route: '/hierarchy',
                    name: 'Hierarchie'
                },
                {
                    route: '/estate',
                    name: 'Anwesen'
                },
                {
                    route: '/rituals',
                    name: 'Rituale'
                },
                {
                    route: '/events',
                    name: 'Events'
                },
                {
                    route: '/sakura',
                    name: 'Sakura'
                },
                {
                    route: '/vehicles',
                    name: 'Fahrzeuge'
                },
                {
                    route: '/appearance',
                    name: 'Auftreten'
                },
                {
                    route: '/shogunat',
                    name: 'Shōgunat'
                },
            ]
        };
    },
    mounted() {
    if (this.$refs.audio) {
      this.$refs.audio.volume = 0.1;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../style/shared.scss";
@import "../style/navigation-bar.scss";
</style>
