<template>
  <div id="background"></div>
  <div class="snowflakes" aria-hidden="true">
    <div class="snowflake">
      <img class="blossom1" :src="require('@/assets/images/blossom1.png')" />
    </div>
    <div class="snowflake">
      <img class="blossom2" :src="require('@/assets/images/blossom2.png')" />
    </div>
    <div class="snowflake">
      <img class="blossom3" :src="require('@/assets/images/blossom3.png')" />
    </div>
    <div class="snowflake">
      <img class="blossom4" :src="require('@/assets/images/blossom4.png')" />
    </div>
    <div class="snowflake">
      <img class="blossom5" :src="require('@/assets/images/blossom5.png')" />
    </div>
    <div class="snowflake">
      <img class="blossom2" :src="require('@/assets/images/blossom2.png')" />
    </div>
    <div class="snowflake">
      <img class="blossom4" :src="require('@/assets/images/blossom4.png')" />
    </div>
    <div class="snowflake">
      <img class="blossom2" :src="require('@/assets/images/blossom2.png')" />
    </div>
    <div class="snowflake">
      <img class="blossom5" :src="require('@/assets/images/blossom5.png')" />
    </div>
    <div class="snowflake">
      <img class="blossom1" :src="require('@/assets/images/blossom1.png')" />
    </div>
    <div class="snowflake">
      <img class="blossom1" :src="require('@/assets/images/blossom1.png')" />
    </div>
    <div class="snowflake">
      <img class="blossom3" :src="require('@/assets/images/blossom3.png')" />
    </div>
    <div class="snowflake">
      <img class="blossom4" :src="require('@/assets/images/blossom4.png')" />
    </div>
    <div class="snowflake">
      <img class="blossom2" :src="require('@/assets/images/blossom2.png')" />
    </div>
    <div class="snowflake">
      <img class="blossom3" :src="require('@/assets/images/blossom3.png')" />
    </div>
    <div class="snowflake">
      <img class="blossom4" :src="require('@/assets/images/blossom4.png')" />
    </div>
    <div class="snowflake">
      <img class="blossom1" :src="require('@/assets/images/blossom1.png')" />
    </div>
    <div class="snowflake">
      <img class="blossom3" :src="require('@/assets/images/blossom3.png')" />
    </div>
    <div class="snowflake">
      <img class="blossom2" :src="require('@/assets/images/blossom2.png')" />
    </div>
    <div class="snowflake">
      <img class="blossom2" :src="require('@/assets/images/blossom2.png')" />
    </div>
  </div>
  <header>
    <NavigationBar />
  </header>
  <main>
    <router-view></router-view>
  </main>
</template>

<script>
import NavigationBar from './components/NavigationBar.vue';

export default {
  name: 'App',
  components: {
    NavigationBar
  }
}
</script>

<style lang="scss">
::-webkit-scrollbar {
  width: 0.5vh;
}

::-webkit-scrollbar-thumb {
  background: #ad1625;
  border-radius: 50px;
}

::-webkit-scrollbar-track {
  background: rgb(0, 0, 0, 0.9);
}

body {
  margin: 0;
}

#background {
  position: fixed;
  z-index: -9999;
  background-image: url('./assets/images/background.png');
  height: 100vh;
  width: 100vw;
  filter: brightness(20%)
}

/* Cherry Blossom */

@media screen and (max-width: 800px) and (min-width: 0px) {
  .snowflakes {
    display: none;
  }
}

@-webkit-keyframes snowflakes-fall {
  0% {
    top: 5vh
  }

  100% {
    top: 100%
  }
}

@-webkit-keyframes snowflakes-shake {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px)
  }

  50% {
    -webkit-transform: translateX(30px);
    transform: translateX(30px)
  }

  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px)
  }
}

@keyframes snowflakes-fall {
  0% {
    top: 5vh
  }

  100% {
    top: 100%
  }
}

@keyframes snowflakes-shake {
  0% {
    transform: translateX(0px)
  }

  50% {
    transform: translateX(30px)
  }

  100% {
    transform: translateX(0px)
  }
}

.snowflake {
  position: fixed;
  top: -10%;
  z-index: 9;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  -webkit-animation-name: snowflakes-fall, snowflakes-shake;
  -webkit-animation-duration: 10s, 3s;
  -webkit-animation-timing-function: linear, ease-in-out;
  -webkit-animation-iteration-count: infinite, infinite;
  -webkit-animation-play-state: running, running;
  animation-name: snowflakes-fall, snowflakes-shake;
  animation-duration: 10s, 3s;
  animation-timing-function: linear, ease-in-out;
  animation-iteration-count: infinite, infinite;
  animation-play-state: running, running;

  .blossom1 {
    width: 2vh;
  }

  .blossom2 {
    width: 1vh;
  }

  .blossom3 {
    width: 1.5vh;
  }

  .blossom4 {
    width: 1.5vh;
  }

  .blossom5 {
    width: 2.5vh;
  }
}

.snowflake:nth-of-type(0) {
  left: 1%;
  -webkit-animation-delay: 0s, 0s;
  animation-delay: 0s, 0s;
}

.snowflake:nth-of-type(1) {
  left: 10%;
  -webkit-animation-delay: 1s, 1s;
  animation-delay: 1s, 1s;
}

.snowflake:nth-of-type(2) {
  left: 20%;
  -webkit-animation-delay: 6s, .5s;
  animation-delay: 6s, .5s;
}

.snowflake:nth-of-type(3) {
  left: 30%;
  -webkit-animation-delay: 4s, 2s;
  animation-delay: 4s, 2s;
}

.snowflake:nth-of-type(4) {
  left: 40%;
  -webkit-animation-delay: 2s, 2s;
  animation-delay: 2s, 2s;
}

.snowflake:nth-of-type(5) {
  left: 50%;
  -webkit-animation-delay: 8s, 3s;
  animation-delay: 8s, 3s;
}

.snowflake:nth-of-type(6) {
  left: 60%;
  -webkit-animation-delay: 6s, 2s;
  animation-delay: 6s, 2s;
}

.snowflake:nth-of-type(7) {
  left: 70%;
  -webkit-animation-delay: 2.5s, 1s;
  animation-delay: 2.5s, 1s;
}

.snowflake:nth-of-type(8) {
  left: 80%;
  -webkit-animation-delay: 1s, 0s;
  animation-delay: 1s, 0s;
}

.snowflake:nth-of-type(9) {
  left: 90%;
  -webkit-animation-delay: 3s, 1.5s;
  animation-delay: 3s, 1.5s;
}

.snowflake:nth-of-type(10) {
  left: 5%;
  -webkit-animation-delay: 4.75s, 2s;
  animation-delay: 4.75s, 2s;
}

.snowflake:nth-of-type(11) {
  left: 15%;
  -webkit-animation-delay: 4.25s, 1s;
  animation-delay: 4.25s, 1s;
}

.snowflake:nth-of-type(12) {
  left: 25%;
  -webkit-animation-delay: 7s, 3s;
  animation-delay: 7s, 3s;
}

.snowflake:nth-of-type(13) {
  left: 35%;
  -webkit-animation-delay: 5.5s, 2s;
  animation-delay: 5.5s, 2s;
}

.snowflake:nth-of-type(14) {
  left: 45%;
  -webkit-animation-delay: 4.5s, 3.5s;
  animation-delay: 4.5s, 3.5s;
}

.snowflake:nth-of-type(15) {
  left: 55%;
  -webkit-animation-delay: 0s, 2s;
  animation-delay: 0s, 2s;
}

.snowflake:nth-of-type(16) {
  left: 65%;
  -webkit-animation-delay: 2s, 0.5s;
  animation-delay: 2s, 0.5s;
}

.snowflake:nth-of-type(17) {
  left: 75%;
  -webkit-animation-delay: 7.5s, 5s;
  animation-delay: 7.5s, 5s;
}

.snowflake:nth-of-type(18) {
  left: 85%;
  -webkit-animation-delay: 3.5s, 1s;
  animation-delay: 3.5s, 1s;
}

.snowflake:nth-of-type(19) {
  left: 95%;
  -webkit-animation-delay: 5s, 3.5s;
  animation-delay: 5s, 3.5s;
}
</style>
