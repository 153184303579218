import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../components/HomeView.vue';
import HistoryView from '../components/HistoryView.vue';
import HierarchyView from '../components/HierarchyView.vue';
import RitualsView from '../components/RitualsView.vue';
import EventsView from '../components/EventsView.vue';
import SakuraView from '../components/SakuraView.vue';
import EstateView from '../components/EstateView.vue';
import VehicleView from '../components/VehicleView.vue';
import AppearanceView from '../components/AppearanceView.vue';
import ShogunatView from '../components/ShogunatView.vue';

const routes = [
  {
    path: '/',
    name: 'Startseite',
    component: HomeView
  },
  {
    path: '/history',
    name: 'Historie',
    component: HistoryView
  },
  {
    path: '/hierarchy',
    name: 'Hierarchie',
    component: HierarchyView
  },
  {
    path: '/rituals',
    name: 'Rituale',
    component: RitualsView
  },
  {
    path: '/events',
    name: 'Events',
    component: EventsView
  },
  {
    path: '/sakura',
    name: 'Sakura',
    component: SakuraView
  },
  {
    path: '/estate',
    name: 'Anwesen',
    component: EstateView
  },
  {
    path: '/vehicles',
    name: 'Fahrzeuge',
    component: VehicleView
  },
  {
    path: '/appearance',
    name: 'Auftreten',
    component: AppearanceView
  },
  {
    path: '/shogunat',
    name: 'Shōgunat',
    component: ShogunatView
  },
  { 
    path: '/:pathMatch(.*)', 
    component: HomeView 
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;