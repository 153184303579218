<template>
    <div class="wrapper">
        <div class="headline">
            <div class="name hiddenHeader">Sakura</div>
            <div class="chars hiddenHeader">桜</div>
            <img class="image hiddenHeader" :src="require('@/assets/images/sakura.png')">
        </div>
        <div class="body">
            <div class="description" v-for="(item, index) in sakuraData" :key="index">
                <div class="name hidden" v-text="item.name"></div>
                <div class="char hidden" v-text="item.chars"></div>
                <div class="hidden" v-html="item.description"></div>
                <img v-if="item.image" class="imageDrug hiddenHeader" :src="require('@/assets/images/' + item.image)">
                <hr class="horizontal-line">
            </div>
        </div>
    </div>
</template>

<script>
import BaseView from './BaseView.vue';

export default {
    name: 'SakuraView',
    extends: BaseView,
    data() {
        return {
            hiddenHeaderElements: [],
            hiddenElements: [],
            sakuraData: [
                {
                    name: "Symbol",
                    chars: "象徴",
                    description:
                        "<p>Für den Miyamoto Clan ist die Sakura, die Kirschblüte, nicht nur eine einfache Blume, sondern ein Symbol von tiefgreifender Bedeutung und spiritueller Verbundenheit mit ihrer japanischen Identität und Geschichte. Die Sakura repräsentiert die Schönheit, Vergänglichkeit und Wiedergeburt, und sie hat eine lange Tradition in der Kultur und Kunst Japans.</p><p>Der Miyamoto Clan betrachtet die Sakura als ein Symbol der Hoffnung und des Neuanfangs. In den frühen Frühlingstagen, wenn die zarten rosa Blüten die Äste der Bäume bedecken, feiert der Clan das Erwachen der Natur und die Möglichkeit für persönliches Wachstum und Erneuerung.</p><p>Die Kirschblüte ist auch ein Zeichen der Vergänglichkeit des Lebens, was den Mitgliedern des Miyamoto Clans eine tiefe Wertschätzung für den Moment und die Schönheit des Augenblicks lehrt. Sie erinnert sie daran, jeden Moment zu schätzen und das Beste aus ihrem Leben zu machen.</p><p>Darüber hinaus ist die Sakura ein Symbol der Einheit und Verbundenheit innerhalb des Clans. Wenn die Mitglieder des Miyamoto Clans zusammenkommen, um die Kirschblüte zu bewundern, stärken sie ihre Bindung zueinander und erinnern sich an die Werte und Traditionen, die sie als Clan vereinen.</p>",
                },
                {
                    name: "Versuchung",
                    chars: "誘惑",
                    description:
                        "<p>Die zarten rosa Blüten, die jedes Frühjahr in Japan in voller Blüte stehen, verzaubern die Sinne und ziehen die Menschen in ihren Bann. Doch wie die Schönheit selbst, kann auch die Sakura eine Versuchung sein, deren Auswirkungen sowohl erhebend als auch gefährlich sein können.</p><p>Im Schatten der Kirschblütenbäume verbirgt sich eine geheime Praxis des Miyamoto Clans, der eine Droge namens Sakura herstellt. Diese Substanz verspricht eine ekstatische Wirkung, die die Sinne betört und die Seele beflügelt. Doch wie die Blütenblätter, die im Wind davongetragen werden, kann auch die Wirkung dieser Droge flüchtig und vergänglich sein.</p><p>Die Sakura-Droge birgt ein dunkles Geheimnis, denn ihr Genuss kann schnell zu einem Albtraum werden. Halluzinationen von alten Geistern und verlorenen Seelen können diejenigen heimsuchen, die sich der Versuchung hingeben. Was zunächst wie eine Reise in ein Paradies der Sinne erscheint, kann sich rasch in einen düsteren Pfad verwandeln, auf dem die Grenzen zwischen Realität und Illusion verschwimmen.</p>",
                    image: "sakuraDrug.png"
                },
            ]
        }
    },
    mounted() {
        window.scrollTo(0, 0);
        this.hiddenHeaderElements = document.querySelectorAll('.hiddenHeader');
        this.hiddenElements = document.querySelectorAll('.hidden');
        this.scrollBehaviour(true, this.hiddenHeaderElements, []);
        this.scrollBehaviour(false, [], this.hiddenElements);
    }
}
</script>

<style lang="scss" scoped>
@import "../style/shared.scss";
@import "../style/sakura-view.scss";
</style>
