<script>
export default {
    name: 'BaseView',
    methods: {
        scrollBehaviour(isHeader, hiddenHeaderElements, hiddenElements) {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        if (isHeader) {
                            entry.target.classList.add('showHeader');
                        } else {
                            entry.target.classList.add('show');
                        }
                    }
                });
            });

            if (isHeader) {
                hiddenHeaderElements.forEach((el) => observer.observe(el));
            } else {
                hiddenElements.forEach((el) => observer.observe(el));
            }
        }
    },
}
</script>