<template>
    <div class="wrapper">
        <div class="headline">
            <div class="name hiddenHeader">Fahrzeuge</div>
            <div class="chars hiddenHeader">車両</div>
            <img class="image hiddenHeader" :src="require('@/assets/images/vehicles.png')">
        </div>
        <div class="body">
            <div v-for="(item, index) in estateData" :key="index">
                <div class="name hidden" v-text="item.name"></div>
                <div class="image-group hidden">
                    <img v-for="(image, index2) in item.images" :key="index2" :src="require('@/assets/images/vehicles/' + image)">
                </div>
                <hr class="horizontal-line">
            </div>
        </div>
    </div>
</template>

<script>
import BaseView from './BaseView.vue';

export default {
    name: 'VehicleView',
    extends: BaseView,
    data() {
        return {
            hiddenHeaderElements: [],
            hiddenElements: [],
            estateData: [
                {
                    name: "S-Klasse",
                    images: ["sklasse.jpg"]
                },
                {
                    name: "Schafter",
                    images: ["schafter.jpg"]
                },
                {
                    name: "Drafter",
                    images: ["drafter.jpg"]
                },
                {
                    name: "GT500",
                    images: ["gt500.jpg"]
                },
                {
                    name: "Feltzer3",
                    images: ["feltzetr3.jpg"]
                },
                {
                    name: "Btype3",
                    images: ["bytpe3.jpg"]
                },
                {
                    name: "SuperD",
                    images: ["superd.jpg"]
                },
                {
                    name: "Stafford",
                    images: ["stafford.jpg"]
                },
            ]
        }
    },
    mounted() {
        window.scrollTo(0, 0);
        this.hiddenHeaderElements = document.querySelectorAll('.hiddenHeader');
        this.hiddenElements = document.querySelectorAll('.hidden');
        this.scrollBehaviour(true, this.hiddenHeaderElements, []);
        this.scrollBehaviour(false, [], this.hiddenElements);
    }
}
</script>

<style lang="scss" scoped>
@import "../style/shared.scss";
@import "../style/estate-view.scss";
</style>
